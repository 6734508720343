import React from 'react'
import styled from 'styled-components'

const Button = styled.button`
    border : none;
    background-color : #21372ff1;
    color : #ffffffc9;
    width : 148px;
    height : 48px;
    font-size : 20px;
    cursor: pointer;
    color : #ffffffcf;
    transition-duration : 0.1s;
    font-family : Gowun Batang;
    &&:hover{
        background-color: #182922f0;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        color : #ffffff;
    }
    
`
function ButtonC(props) {
  return (
    <Button onClick={props.onClick}>{props.text}</Button>
  )
}

export default ButtonC;