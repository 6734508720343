import React from 'react'
import styled from 'styled-components'
import CashmereImage from '../../assets/images/Home/cashmere-1.jpg'
import HempImage from '../../assets/images/Home/hemp-Stitching.png'
import PashminaImage from '../../assets/images/Home/cashmere-side-image.png'
import LogoImage from '../../assets/images/logo/logo-gold.png'
import Fabric from '../../assets/images/Home/fabric.png'
import ButtonA from '../button/ButtonA'
import TitleA from '../title/TitleA'
import { useNavigate } from 'react-router-dom'
import { fadeIn } from '../animations/FadeIn'
import { InView, useInView } from 'react-intersection-observer'
import ButtonC from '../button/ButtonC'
import TitleB from '../title/TitleB'
import StampImage from '../../assets/images/Home/stamp.png'

const Wrapper = styled.div`
  animation : appear 2s;
  background-color : #f1fbf2cb;
  animation: ${({ inView }) => (inView ? fadeIn : 'none')} 2s forwards;
`
const Container = styled.div`
  display : flex;
  flex-direction : column;
  justify-content : space-between;
  align-items : center;
  width : fit-content;
  margin : auto;
  @media (max-width: 800px) {
    flex-direction : column;
  }
  p{
    color: #494949;
  }
`

const Main = styled.div`
  height : fit-content;
  max-width : 80vw;
  padding : 10px 15px;  
  padding-left : 0; 
  vertical-align : center;
  h1{
    font-weight : 100;
    margin-left : 0;
    text-align :center;
  }
  p{
    color: #494949;
  }
  @media (max-width : 1000px) {
    transform : none;
  }
  @media (max-width: 800px) {
    padding-top : 0px;
    text-align : center;
    width : 90vw;
    margin-left : auto;
    h1{
      width : 100%;  
      text-align : center;
    }
    p{
      text-align : left;
      margin-left : 10px;
    }
  }
`
const Top = styled.div`
    position : relative;
    padding-top : 60px;
`
const Stamp = styled.div`
    img{
        height : 150px;
        rotate : 15deg;
    }
    position : absolute;
    right : -6vw;
    top : 0vh;
`
const Buttons = styled.div`
    width : fit-content;
    margin : 40px auto;
    button{
        margin : auto 40px;
    }
`

const Lower = styled.div`
  position : relative;
  background-color : #e8f5e948;
  margin-top : 40px;
  padding-top : 40px;
  width : 100vw;
  img{
    max-width : 500px;
    z-index : 99;
  }
  div{
    height : fit-content;
    width : fit-content;
    z-index : 1;
  }
  @media (max-width: 700px) {
    img{
      height : 550px;
    }
    width : fit-content;
    /* margin-bottom : 20px; */
    padding-top : 20px;
  }
  @media (max-width : 550px) {
    img{
      height : 400px;
    }
  }
`
const ImgSection = styled.div`
    margin : auto;
    margin-bottom : 40px;
`
const SmContainer = styled.div`
    max-width : 80vw;
    margin : auto;
    display : flex;
    align-items : center;
    @media (max-width : 650px) {
        flex-direction : column;
        align-items : start;
    }
`
const ImageArea = styled.div`
  width : fit-content;
  display : flex;
  flex-direction : column;
  align-items : center;
    img{
        /* width: 300px; */
        height : 528px;
        /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
    }
    img:nth-child(2){
      opacity : 0.8;
      padding : 30px;
      height : 90px;
      width : 120px;
    }
`
const TextArea = styled.div`
    font-size : 16px;
    p{
        letter-spacing : 0.6px;
    }
    a{
      color: #121010;
      font-size : 17px;
    }
    @media (max-width : 650px) {
        margin : 0px !important;
    }
`
function OrganicProduction() {
  const navigate = useNavigate();
  const {ref, inView} = useInView({
    triggerOnce : true
  })
  return (
    <Wrapper ref={ref} inView={inView}>
      <Container>
        <Main>
            <Top>
                <Stamp>
                    <img src={StampImage} alt="organic" />
                </Stamp>
                <TitleA text={"Forall's Commitment to Maintaining Quality in Organic Production"}/>
            </Top>
            <p>Forall is committed to delivering the highest quality fabrics, sourced directly from Nepal. Our expert artisans handcraft each luxurious pashmina piece with precision, using years of design experience. Similarly, our eco-friendly hemp garments are crafted from locally sourced materials, combining sustainability with exceptional craftsmanship. Every Forall product reflects our dedication to quality, tradition, and superior workmanship, ensuring an unparalleled experience.</p>
              {/* <Buttons>
                <ButtonC text = {"Hemp"} onClick={()=>{navigate("/services")}}/>
                <ButtonC text = {"Pashmina"} onClick={()=>{navigate("/services")}}/>
              </Buttons> */}
        </Main>

        <Lower>
            <ImgSection>
                    <SmContainer>
                        <ImageArea style={{order : '2'}}>
                            <img src={PashminaImage} alt="missing" />
                            <img src= {LogoImage}alt=""/>
                        </ImageArea>
                        <TextArea style={{marginRight:'20px'}}>
                            <TitleB text ={"Finest Cashmere Weave"}/>
                            <p>Forall takes immense pride in being a premier manufacturer of <a>lab-certified</a>, authentic cashmere derived from the pristine Himalayan regions of Nepal. Our mission goes beyond supplying premium materials—we are committed to creating sustainable value throughout the cashmere supply chain. Every fiber we produce reflects an unwavering dedication to quality, environmental responsibility, and ethical sourcing.
                              Our cashmere is <a >hand-harvested</a> to ensure only the finest fibers make it into your collection. With stringent quality control processes and lab certification, we guarantee the purity and durability of every batch, ensuring it meets the highest industry standards. Our manufacturing approach preserves the softness, warmth, and longevity that define true luxury cashmere.
                              <br/><br/><a>Our offerings</a> include everything from exquisitely soft scarves and shawls to versatile sweaters, cardigans, and outerwear—all made entirely from the finest cashmere. As an innovative design house, we also provide custom manufacturing services, transforming customer designs into reality with our premium cashmere. With an unwavering commitment to quality and craftsmanship, we ensure that each tailored piece embodies your vision and showcases exceptional attention to detail.
                              <br/><br/><a>Sustainability</a> is at the heart of everything we do. We actively work to minimize our environmental impact by adopting eco-friendly practices throughout production. Partnering closely with local herders and artisans, we safeguard traditional livelihoods, provide fair wages, and support communities that depend on cashmere for their well-being.
                              <br/><br/><a>Animal welfare</a> is paramount in our operations. We ensure that animals are treated with care and respect, using gentle combing techniques during the molting season to collect cashmere without causing harm. This commitment not only preserves the natural ecosystem but also fosters sustainable farming practices.
                              <br/><br/>The Himalayan cashmere is more than a fabric—it's a testament to craftsmanship, sustainability, and ethical responsibility. Through our thoughtful approach to design and craftsmanship, we deliver not just products but stories—woven from the purity of Himalayan cashmere, shaped by heritage, and perfected with modern flair.</p>
                        </TextArea>
                    </SmContainer>
                  {/* <SmContainer>
                      <ImageArea>
                          <img src={HempImage} alt="missing" />
                      </ImageArea>
                      <TextArea style={{marginLeft:'20px'}}>
                          <TitleB text ={"Hemp with Forall"}/>
                          <p>Forall excels in producing premium hemp apparel, offering an extensive range of eco-friendly products such as hemp bags, socks, jackets, purses, and much more. Our sustainable hemp garments are crafted with the same attention to detail and quality that defines our brand. Each item is designed to be durable, comfortable, and stylish, reflecting our commitment to environmental responsibility. </p>
                      </TextArea>
                  </SmContainer> */}
            </ImgSection>
        </Lower>
      </Container>

    </Wrapper>
  )
}

export default OrganicProduction